import React, { useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useBreadCrumbs } from '../hooks/useBreadcrumbs'
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  Icon,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
} from '@material-ui/core'

const ShopsPage = props => {
  const breadCrumbs = useBreadCrumbs('shops')
  const shops = props.data.allContentfulShops.edges

  const [filter, setFilter] = useState('Все')

  const { filters } = shops.reduce(
    (acc, { node: shop }) => {
      if (acc.hash[shop.type]) {
        return acc
      }
      return {
        hash: { ...acc.hash, [shop.type]: true },
        filters: [...acc.filters, shop.type],
      }
    },
    { hash: {}, filters: [] }
  )

  return (
    <Layout breadCrumbs={breadCrumbs} title="Магазины">
      <SEO title="Где купить пенетрон в томске" />
      <Grid container direction="column-reverse">
        <Grid item md={6} xs={12}>
          {shops.map(({ node: shop }) => {
            if (filter !== 'Все' && shop.type !== filter) {
              return null
            }

            const isThisSiteLink = shop.link === 'self'
            const link = isThisSiteLink ? '/contacts' : shop.link
            const buttonText = isThisSiteLink
              ? 'Оформить заявку'
              : 'Перейти на сайт'
            const buttonIcon = isThisSiteLink ? 'thumb_up' : 'arrow_right_alt'
            const buttonTarget = isThisSiteLink ? '' : '__blank'
            return (
              <Box mb={3} key={shop.id}>
                <Card>
                  <CardContent>
                    <Box mb={2}>
                      <Typography variant="h5" component="h3">
                        {shop.name}
                      </Typography>
                      {shop.additionalInfo && (
                        <Box>
                          <Typography variant="body2" component="p">
                            {shop.additionalInfo}
                          </Typography>
                        </Box>
                      )}
                      <Typography color="textSecondary">{shop.type}</Typography>
                    </Box>
                    <Typography variant="body2" component="p">
                      <strong>Адрес:</strong>
                      <br />
                      {shop.address}
                    </Typography>
                    {shop.phone && (
                      <Typography variant="body2" component="p">
                        <strong>Телефон:</strong>
                        <br />
                        {shop.phone}
                      </Typography>
                    )}
                    {shop.schedule && (
                      <Box>
                        <Typography variant="body2" component="p">
                          <strong>График работы:</strong>
                          <br />
                          {shop.schedule}
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                  {shop.link && (
                    <CardActions>
                      <Button
                        color="primary"
                        endIcon={<Icon>{buttonIcon}</Icon>}
                        href={link}
                        target={buttonTarget}
                      >
                        {buttonText}
                      </Button>
                    </CardActions>
                  )}
                </Card>
              </Box>
            )
          })}
        </Grid>
        <Grid item md={6} xs={12}>
          <Box mb={3}>
            <FormControl variant="filled">
              <InputLabel shrink htmlFor="filter">
                Показывать: {filter}
              </InputLabel>
              <Select
                style={{ minWidth: '300px' }}
                value={filter}
                onChange={e => {
                  setFilter(e.target.value)
                }}
                inputProps={{
                  name: 'filter',
                  id: 'filter',
                }}
              >
                <MenuItem key={'all'} value={'Все'}>
                  Все
                </MenuItem>
                {filters.map(f => (
                  <MenuItem key={f} value={f}>
                    {f}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulShops {
      edges {
        node {
          id
          type
          phone
          name
          address
          additionalInfo
          schedule
          link
        }
      }
    }
  }
`

export default ShopsPage
